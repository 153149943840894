import fileRemover from './images/fileRemover.png'
import acPortfolio from './images/acPortfolio.PNG'
import Notes4U from './images/notes4u.PNG'
import nutritionFacts from './images/nutritionFacts.png'

// eslint-disable-next-line import/no-anonymous-default-export
export default{
    fileRemover,
    acPortfolio,
    Notes4U,
    nutritionFacts
}